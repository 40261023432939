"use client";

import * as React from "react";
import { cn } from "@/utils/tw";
export interface InputProps extends Omit<React.InputHTMLAttributes<HTMLInputElement>, "prefix" | "value"> {
  prefix?: React.ReactNode;
  postfix?: React.ReactNode;
  inputClassName?: string;
  value?: React.InputHTMLAttributes<HTMLInputElement>["value"] | null;
}
export type PureInputProps = React.InputHTMLAttributes<HTMLInputElement>;
const PureInput = React.forwardRef<HTMLInputElement, PureInputProps>(({
  className,
  type,
  ...props
}, ref) => {
  return <input type={type} className={cn("squircle-small flex h-12 w-full border-none bg-black/5 px-3 py-2 font-normal ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground hover:bg-black/10 focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 dark:bg-white/5 hover:dark:bg-white/10", className)} ref={ref} {...props} />;
});
PureInput.displayName = "PureInput";
const Input = React.forwardRef<HTMLInputElement, InputProps>(({
  className,
  type,
  prefix,
  postfix,
  inputClassName,
  disabled,
  onChange,
  value: propsValue,
  defaultValue,
  ...props
}, ref) => {
  const [value, setValue] = React.useState(propsValue || defaultValue || "");
  const onChangeCallback = React.useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
    if (!disabled) {
      onChange?.(e);
    }
  }, [onChange, disabled]);
  React.useEffect(() => {
    setValue(defaultValue || "");
  }, [defaultValue]);
  React.useEffect(() => {
    setValue(propsValue || "");
  }, [propsValue]);
  return <div className={cn("squircle-small flex h-12 w-full items-center gap-2 border-none bg-black/5 px-4 py-1 font-normal text-black ring-offset-background file:border-0 file:bg-transparent file:text-base file:font-medium placeholder:text-muted-foreground hover:bg-black/10 focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 aria-disabled:cursor-not-allowed aria-disabled:bg-stroke-gray/20 dark:bg-white/5 hover:dark:bg-white/10", className)} aria-disabled={disabled}>
        {prefix}
        <input type={type} ref={ref} className={cn("w-full flex-1 bg-transparent text-base focus-visible:outline-none disabled:cursor-not-allowed disabled:bg-transparent dark:text-white", inputClassName)} {...props} disabled={disabled} onChange={onChangeCallback} value={value || ""} />
        {postfix}
      </div>;
});
Input.displayName = "Input";
export { Input, PureInput };