import { forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState } from "react";
import { cn } from "@/utils/tw";
import * as RPopover from "@radix-ui/react-popover";
import { Icon } from "./Icon";
export type PopoverRef = {
  setOpen: (open: boolean) => void;
  open: boolean;
};
export const Popover = forwardRef<PopoverRef, {
  children: React.ReactNode;
  content?: React.ReactNode;
  hideClose?: boolean;
  arrowClassName?: string;
  wrapperClassName?: string;
  triggerClassName?: string;
  hideArrow?: boolean;
  sideOffset?: number;
  open?: boolean;
  onOpenChange?: (open: boolean) => void;
  appendTo?: "parent";
  disabled?: boolean;
  closeIcon?: React.ReactNode;
}>(({
  children,
  content,
  hideClose,
  hideArrow,
  wrapperClassName,
  triggerClassName,
  sideOffset,
  arrowClassName = "fill-white",
  open: propsOpen,
  onOpenChange: propsOnOpenChange,
  appendTo,
  disabled,
  closeIcon
}, ref) => {
  const [open, setOpen] = useState(propsOpen || false);
  useImperativeHandle(ref, function getRefValue() {
    return {
      // new ref value...
      setOpen: setOpen,
      open: open
    };
  }, [open]);
  useEffect(() => {
    setOpen(propsOpen || false);
  }, [propsOpen]);
  const buttonRef = useRef<HTMLElement | null>(null);
  const onOpenChange = useCallback((isOpen: boolean) => {
    setOpen(isOpen);
    propsOnOpenChange?.(isOpen);
  }, [propsOnOpenChange]);
  if (!content) return children;
  const triggerContent = <div className={cn("group/popovertrigger cursor-pointer [&[data-state=open]>*]:opacity-100", triggerClassName)} ref={newRef => {
    buttonRef.current = newRef;
  }}>
        {children}
      </div>;
  if (disabled || !content) {
    return triggerContent;
  }
  return <RPopover.Root open={open} onOpenChange={onOpenChange}>
        <RPopover.Trigger asChild={true}>{triggerContent}</RPopover.Trigger>
        <RPopover.Portal container={appendTo === "parent" ? buttonRef.current : undefined}>
          <RPopover.Content className={cn("PopoverContent z-[60] overflow-auto", wrapperClassName)} sideOffset={sideOffset}>
            {!hideClose && <RPopover.Close className="absolute right-2 top-2 z-10 flex items-center justify-center">
                <Icon icon="close" size="small" />
              </RPopover.Close>}
            {closeIcon && <RPopover.Close className="absolute right-2 top-2 z-10 flex items-center justify-center">
                {closeIcon}
              </RPopover.Close>}
            {!hideArrow && <RPopover.Arrow className={arrowClassName} />}
            {content}
          </RPopover.Content>
        </RPopover.Portal>
      </RPopover.Root>;
});
Popover.displayName = "Popover";