import { cn } from "@/utils/tw";
import { Icon } from "../Icon";
export const Loader = (props: {
  variant?: "light" | "dark";
}) => <div className="absolute left-0 top-0 z-30 flex h-full w-full items-center justify-center align-middle" data-sentry-component="Loader" data-sentry-source-file="Loader.tsx">
    <Icon icon="loading" variant="ghost" className={cn("animate-spin", props.variant === "light" ? "text-white" : "text-inactive-gray")} size="large" data-sentry-element="Icon" data-sentry-source-file="Loader.tsx" />
  </div>;
export function LoadingOverlay(props: {
  loading: boolean;
  message?: string;
}) {
  if (!props.loading) return null;
  return <div className="absolute inset-0 z-50 flex items-center justify-center rounded-lg bg-black bg-opacity-50" data-sentry-component="LoadingOverlay" data-sentry-source-file="Loader.tsx">
      <Loader variant="light" data-sentry-element="Loader" data-sentry-source-file="Loader.tsx" />
      {props.message && <div className="mt-20">{props.message}</div>}
    </div>;
}