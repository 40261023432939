"use client";

import * as React from "react";
import { Command, CommandEmpty, CommandGroup, CommandInput, CommandItem, CommandList } from "@/components/ui/Command";
import { Drawer, DrawerContent, DrawerTrigger } from "@/components/ui/Drawer";
import { useMediaQuery } from "@/hooks/useMediaQuery";
import { cn } from "@/utils/tw";
import { ChevronsUpDown } from "lucide-react";
import { DialogDescription, DialogTitle } from "../Dialog2";
import { Popover } from "../Popover";
export function ResponsivePopover(props: {
  children: React.ReactNode;
  content: React.ReactNode;
  title?: string;
  description?: string;
  disabled?: boolean;
  open?: boolean;
  setOpen?: (open: boolean) => void;
  wrapperClassName?: string;
  triggerClassName?: string;
}) {
  const isDesktop = useMediaQuery("(min-width: 768px)");
  if (props.disabled) {
    return <>{props.children}</>;
  }
  if (isDesktop) {
    return <Popover open={props.open} onOpenChange={props.setOpen} content={props.content} wrapperClassName={props.wrapperClassName} disabled={props.disabled} triggerClassName={props.triggerClassName} hideClose>
        {props.children}
      </Popover>;
  }
  return <Drawer open={props.open} onOpenChange={props.setOpen} data-sentry-element="Drawer" data-sentry-component="ResponsivePopover" data-sentry-source-file="Combobox.tsx">
      <DrawerTrigger asChild className={props.triggerClassName} data-sentry-element="DrawerTrigger" data-sentry-source-file="Combobox.tsx">
        {props.children}
      </DrawerTrigger>
      <DrawerContent className={props.wrapperClassName} data-sentry-element="DrawerContent" data-sentry-source-file="Combobox.tsx">
        <DialogTitle hidden data-sentry-element="DialogTitle" data-sentry-source-file="Combobox.tsx">{props.title || "Popup"}</DialogTitle>
        <DialogDescription hidden data-sentry-element="DialogDescription" data-sentry-source-file="Combobox.tsx">{props.description || ""}</DialogDescription>
        <div className={cn("mt-4 border-t")}>{props.content}</div>
      </DrawerContent>
    </Drawer>;
}
export function ComboBoxDynamic<TOption = Record<string, unknown>>({
  labelKey = "label" as keyof TOption,
  valueKey = "value" as keyof TOption,
  triggerClassName,
  wrapperClassName,
  value: propsValue,
  ...props
}: {
  options: TOption[];
  placeholder?: string;
  searchPlaceholder?: string;
  labelKey?: keyof TOption;
  valueKey?: keyof TOption;
  uniqueKey?: keyof TOption;
  defaultValue?: TOption;
  value?: string;
  triggerClassName?: string;
  wrapperClassName?: string;
  extraKeywords?: string[];
  onSelectCallback: (option: TOption) => void;
  constructLabel?: (option: TOption) => string;
  constructTriggerLabel?: (option: TOption) => string;
}) {
  const uniqueKey = props.uniqueKey ?? valueKey;
  const [open, setOpen] = React.useState(false);
  const [selectedOption, setSelectedOption] = React.useState<TOption | null>(props.defaultValue ?? null);
  React.useEffect(() => {
    if (propsValue) {
      const option = props.options.find(option => option[valueKey] === propsValue);
      if (option) {
        setSelectedOption(option);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [propsValue]);
  return <ResponsivePopover open={open} setOpen={setOpen} wrapperClassName={wrapperClassName} content={<Command>
          <CommandInput placeholder={props.searchPlaceholder ?? "Search"} autoFocus />
          <CommandList>
            <CommandEmpty>No results found.</CommandEmpty>
            <CommandGroup>
              {props.options.map(option => <CommandItem key={String(option[uniqueKey])} keywords={[String(option[labelKey]), ...(props.extraKeywords ?? [])]} value={String(option[valueKey])} onSelect={_value => {
          setSelectedOption(option);
          setOpen(false);
          props.onSelectCallback(option);
        }}>
                  {props.constructLabel ? props.constructLabel(option) : String(option[labelKey])}
                </CommandItem>)}
            </CommandGroup>
          </CommandList>
        </Command>} data-sentry-element="ResponsivePopover" data-sentry-component="ComboBoxDynamic" data-sentry-source-file="Combobox.tsx">
      <button className={cn("squircle-small flex h-12 w-full items-center justify-between rounded-md border-none bg-black/5 px-3 py-2 text-sm ring-offset-background placeholder:text-muted-foreground hover:bg-black/10 focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 dark:bg-white/5 hover:dark:bg-white/10 [&>span]:line-clamp-1", triggerClassName)} type="button">
        {selectedOption ? props.constructTriggerLabel?.(selectedOption) ?? props.constructLabel?.(selectedOption) ?? String(selectedOption[labelKey]) : String(selectedOption?.[labelKey] ?? props.placeholder ?? "Select")}
        <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" data-sentry-element="ChevronsUpDown" data-sentry-source-file="Combobox.tsx" />
      </button>
    </ResponsivePopover>;
}