"use client";

import type { VariantProps } from "class-variance-authority";
import * as React from "react";
import { cn } from "@/utils/tw";
import * as LabelPrimitive from "@radix-ui/react-label";
import { cva } from "class-variance-authority";
const labelVariants = cva("peer-disabled:cursor-not-allowed peer-disabled:opacity-70");
const Label = React.forwardRef<React.ElementRef<typeof LabelPrimitive.Root>, React.ComponentPropsWithoutRef<typeof LabelPrimitive.Root> & VariantProps<typeof labelVariants> & {
  muted?: string | boolean;
}>(({
  className,
  muted,
  ...props
}, ref) => <LabelPrimitive.Root ref={ref} className={cn(labelVariants(), className, muted ? "text-black" : "")} {...props} />);
Label.displayName = LabelPrimitive.Root.displayName;
export { Label };

// Original / updated pure shad implementation
// import * as React from "react"
// import * as LabelPrimitive from "@radix-ui/react-label"
// import { cva, type VariantProps } from "class-variance-authority"

// import { cn } from "@/lib/utils"

// const labelVariants = cva(
//   "text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
// )

// const Label = React.forwardRef<
//   React.ElementRef<typeof LabelPrimitive.Root>,
//   React.ComponentPropsWithoutRef<typeof LabelPrimitive.Root> &
//     VariantProps<typeof labelVariants>
// >(({ className, ...props }, ref) => (
//   <LabelPrimitive.Root
//     ref={ref}
//     className={cn(labelVariants(), className)}
//     {...props}
//   />
// ))
// Label.displayName = LabelPrimitive.Root.displayName

// export { Label }