import type { ReactNode } from "react";
import React from "react";
import { cn } from "@/utils/tw";
const H1 = React.forwardRef<HTMLHeadingElement, {
  className?: string;
  children?: ReactNode;
  inverted?: boolean;
  strong?: boolean;
} & React.HTMLAttributes<HTMLHeadingElement>>(({
  inverted,
  className,
  strong,
  children,
  ...props
}, ref) => {
  return <h1 ref={ref} {...props} className={cn(className, {
    "text-white": inverted,
    "font-semibold": strong
  })}>
      {children}
    </h1>;
});
H1.displayName = "H1";
const H2 = React.forwardRef<HTMLHeadingElement, {
  className?: string;
  children?: ReactNode;
  inverted?: boolean | string;
  strong?: boolean;
} & React.HTMLAttributes<HTMLHeadingElement>>(({
  inverted,
  strong,
  className,
  children,
  ...props
}, ref) => {
  return <h2 ref={ref} {...props} className={cn(className, {
    "text-white": inverted,
    "font-semibold": strong
  })}>
      {children}
    </h2>;
});
H2.displayName = "H2";
const H3 = React.forwardRef<HTMLHeadingElement, {
  className?: string;
  children?: ReactNode;
  inverted?: boolean;
  strong?: boolean;
  sans?: boolean;
} & React.HTMLAttributes<HTMLHeadingElement>>(({
  inverted,
  sans,
  strong,
  className,
  ...props
}, ref) => {
  return <h3 ref={ref} {...props} className={cn(className, {
    "text-white": inverted,
    "font-semibold": strong,
    "font-sans": sans
  })}>
      {props.children}
    </h3>;
});
H3.displayName = "H3";
const H4 = React.forwardRef<HTMLHeadingElement, {
  className?: string;
  children?: ReactNode;
  inverted?: boolean | string;
  strong?: boolean;
} & React.HTMLAttributes<HTMLHeadingElement>>(({
  inverted,
  strong,
  className,
  ...props
}, ref) => {
  return <h4 ref={ref} {...props} className={cn(className, {
    "text-white": inverted,
    "font-semibold": strong
  })}>
      {props.children}
    </h4>;
});
H4.displayName = "H4";
function Subheading(props: {
  className?: string;
  children: ReactNode;
} & React.HTMLAttributes<HTMLHeadingElement>) {
  return <p {...props} className={cn("mt-2 text-sm text-inactive-gray", props.className)} data-sentry-component="Subheading" data-sentry-source-file="Text.tsx">
      {props.children}
    </p>;
}
const H5 = React.forwardRef<HTMLHeadingElement, {
  className?: string;
  children?: ReactNode;
  inverted?: boolean | string;
  strong?: boolean;
} & React.HTMLAttributes<HTMLHeadingElement>>(({
  inverted,
  strong,
  className,
  ...props
}, ref) => {
  return <h5 ref={ref} {...props} className={cn(className, {
    "text-white": inverted,
    "font-semibold": strong
  })}>
      {props.children}
    </h5>;
});
H5.displayName = "H5";
const H6 = React.forwardRef<HTMLHeadingElement, {
  className?: string;
  children?: ReactNode;
  inverted?: boolean;
  sans?: boolean;
  strong?: boolean;
} & React.HTMLAttributes<HTMLHeadingElement>>(({
  inverted,
  className,
  strong,
  children,
  sans,
  ...props
}, ref) => {
  return <h6 ref={ref} {...props} className={cn(className, {
    "text-white": inverted,
    "font-semibold": strong,
    "font-sans font-normal": sans
  })}>
      {children}
    </h6>;
});
H6.displayName = "H6";
const P = React.forwardRef<HTMLParagraphElement, {
  children?: ReactNode;
  inverted?: string | boolean;
  htmlFor?: string;
  muted?: boolean | string;
  strong?: string | boolean;
  small?: string | boolean;
  /**
   * @deprecated Use `level` instead
   */
  big?: string | boolean;
  destructive?: string | boolean;
  light?: boolean;
  level?: 1 | 2 | 3 | 4;
} & React.HTMLAttributes<HTMLParagraphElement>>(({
  htmlFor,
  className,
  inverted,
  muted,
  strong,
  small,
  destructive,
  big,
  light,
  level = 2,
  ...props
}, ref) => {
  return <p ref={ref} {...props} className={cn({
    "font-normal": light,
    "text-white": inverted,
    "font-bold": strong,
    "opacity-60": muted,
    "text-xs md:text-sm": small || level === 3,
    "text-lg md:text-xl": big || level === 1,
    "break-all text-destructive": destructive,
    "text-xs md:text-xs": level === 4
  }, className)}>
        {props.children}
      </p>;
});
P.displayName = "P";
export const Text = {
  H1,
  H2,
  H3,
  H4,
  H5,
  H6,
  Subheading,
  P
};