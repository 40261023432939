//!  slate for deletion
"use client";

import { useState } from "react";
import { type AppRouter } from "@/server/api/root";
import { useQueryClient } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { getFetch, httpBatchLink, loggerLink } from "@trpc/client";
// https://codevoweb.com/setup-trpc-server-and-client-in-nextjs-13-app-directory/
// import type { AppRouter } from "@/app/api/trpc/trpc-router";
import { createTRPCReact } from "@trpc/react-query";
import superjson from "superjson";
export const trpc = createTRPCReact<AppRouter>();
export const getBaseUrl = () => {
  return "/api/trpc/";
  // ? Below is not needed because we only access trpc from the client. If app router SSR is added to TRPC, we will need the below again.
  // if (typeof window !== "undefined") return ""; // browser should use relative url
  // if (process.env.VERCEL_URL) return `https://${process.env.VERCEL_URL}`; // SSR should use vercel url
  // return `http://localhost:${process.env.PORT ?? 3000}`; // dev SSR should use localhost
};
export const TrpcProvider: React.FC<{
  children: React.ReactNode;
  hideDevtools?: boolean;
}> = ({
  children,
  hideDevtools
}) => {
  const url = getBaseUrl();
  const [trpcClient] = useState(() => trpc.createClient({
    links: [loggerLink({
      enabled: opts => process.env.NODE_ENV === "development" || opts.direction === "down" && opts.result instanceof Error
    }), httpBatchLink({
      url,
      fetch: async (input, init?) => {
        const fetch = getFetch();
        return fetch(input, {
          ...init,
          credentials: "include"
        });
      }
    })],
    transformer: superjson
  }));
  const queryClient = useQueryClient();
  return <trpc.Provider client={trpcClient} queryClient={queryClient} data-sentry-element="unknown" data-sentry-component="TrpcProvider" data-sentry-source-file="TrpcProvider.tsx">
      {children}
      {!hideDevtools && <ReactQueryDevtools />}
    </trpc.Provider>;
};