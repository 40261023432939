"use client";

import { useEffect, useState } from "react";
export function useMediaQuery(query: string) {
  const [value, setValue] = useState(false);
  useEffect(() => {
    function onChange(event: MediaQueryListEvent) {
      setValue(event.matches);
    }
    const result = matchMedia(query);
    result.addEventListener("change", onChange);
    setValue(result.matches);
    return () => result.removeEventListener("change", onChange);
  }, [query]);
  return value;
}
export function detectOS() {
  const userAgent = navigator.userAgent || navigator.vendor;

  // Checking for Android based on user agent
  if (/android/i.test(userAgent)) {
    return "Android";
  }

  // Checking for iOS based on user agent
  if (/iPad|iPhone|iPod/.test(userAgent) && !("MSStream" in window)) {
    return "iOS";
  }
  return "Unknown";
}